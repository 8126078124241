<template>
  <v-slide-x-transition>
    <v-btn
      v-show="filtersAreActive"
      :icon="$vuetify.breakpoint.smAndDown"
      text
      rounded
      @click="$bus.$emit('removeAllActiveFilters')"
    >
      <v-icon :left="$vuetify.breakpoint.mdAndUp">
        {{ $vuetify.icons.values.clearFilter }}
      </v-icon>
      <span class="hidden-sm-and-down">{{ $t('general.filters.clearFilters') }}</span>
    </v-btn>
  </v-slide-x-transition>
</template>

<script>
export default {
  name: 'ResetFiltersButton',
  props: {
    filtersAreActive: { type: Boolean, default: false }
  }

}
</script>

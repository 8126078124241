export const filterChips = {
  props: {
    activeFilters: { type: Object, default: () => { return {} } }
  },
  methods: {
    removeActiveFilter (index, key) {
      this.$bus.$emit('removeActiveFilter', index, key)
    }
  }
}

import { mapGetters, mapActions } from 'vuex'

export const props = {
  props: {
    context: { type: String, default: '' },
    loadingData: { type: Boolean, default: false },
    pipelines: { type: Array, default: () => { return [] } }
  }
}

export const getWorkspacePipelines = {
  created () {
    this.getActiveWorkspacePipelines()
  },
  computed: {
    ...mapGetters([
      'activeWorkspacePipelines',
      'getPipelinesLoadingStatus'
    ])
  },
  methods: {
    ...mapActions([
      'getActiveWorkspacePipelines'
    ])
  }
}

export const mounted = {
  mounted () {
    this.$bus.$on('removeAllActiveFilters', this.removeAllActiveFilters)
    this.$bus.$on('removeActiveFilter', (index, key) => {
      this.removeActiveFilter(index, key)
    })
    this.$bus.$on('trigger:update-active-filters', this.updateActiveFilters)
  }
}

export const beforeDestroy = {
  beforeDestroy () {
    this.$bus.$off('removeAllActiveFilters')
    this.$bus.$off('removeActiveFilter')
    this.$bus.$off('trigger:update-active-filters')
  }
}

export const computedProperties = {
  computed: {
    locale () {
      return this.$i18n.locale
    },
    formState () {
      const formKeys = Object.keys(this.form)
      const cleanedData = formKeys.filter(key => {
        return Array.isArray(this.form[key])
          ? this.form[key].length > 0
          : this.form[key]
      })
      const activeFilters = cleanedData.reduce((result, key) => {
        result[key] = this.form[key]
        return result
      }, {})
      return activeFilters
    }
  }
}

export const commonMethods = {
  methods: {
    updateActiveFilters () {
      this.$emit('update:active-filters', this.formState)
    },
    removeActiveFilter (index, key) {
      this.form[key] = null
      this.updateActiveFilters()
    },
    removeAllActiveFilters () {
      Object.keys(this.form).forEach(key => {
        this.form[key] = null
      })
      this.updateActiveFilters()
    }
  }
}

<template>
  <v-toolbar>
    <v-btn
      text
      rounded
      :disabled="loadingData"
      :loading="loadingData"
      class="blue--text"
      @click="$bus.emit('trigger:update-active-filters')"
    >
      <v-icon left>
        {{ $vuetify.icons.values.check }}
      </v-icon>
      {{ $t('general.filters.apply') }}
    </v-btn>
    <v-btn
      text
      rounded
      @click="$emit('close-filters')"
    >
      <v-icon left>
        {{ $vuetify.icons.values.close }}
      </v-icon>
      {{ $t('general.close') }}
    </v-btn>
  </v-toolbar>
</template>

<script>
export default {
  name: 'FiltersBottomToolbar',
  props: {
    loadingData: { type: Boolean, default: false }
  }
}
</script>

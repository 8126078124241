<template>
  <v-toolbar
    class="transparent"
    flat
  >
    <v-toolbar-title
      v-if="context.enableTitle"
      class="mx-4"
    >
      {{ $t(context.title) }}
    </v-toolbar-title>
    <open-filters-button
      v-if="context.enableFilters"
      :expand-table-filters="expandTableFilters"
      @toggle-table-filters="$emit('update:expand-table-filters', !expandTableFilters)"
    />
    <v-fade-transition>
      <v-divider
        v-show="filtersAreActive"
        inset
        vertical
        class="mx-3"
      />
    </v-fade-transition>
    <reset-filters-button :filters-are-active="filtersAreActive" />
    <v-spacer />
    <open-settings-button
      v-if="context.enableSettings"
      :expand-settings="expandSettings"
      @toggle-settings="$emit('update:expand-settings', !expandSettings)"
    />
    <refresh-data-button
      v-if="context.enableRefresh"
      :loading-data="loadingData"
      @refreshData="$emit('refreshData')"
    />
  </v-toolbar>
</template>

<script>
import OpenFiltersButton from '@/components/buttons/filters/OpenFilters'
import ResetFiltersButton from '@/components/buttons/filters/ResetFilters'
import OpenSettingsButton from '@/components/buttons/settings/OpenSettings'
import RefreshDataButton from '@/components/buttons/RefreshDataButton'

export default {
  name: 'TableToolbar',
  components: {
    OpenFiltersButton,
    ResetFiltersButton,
    OpenSettingsButton,
    RefreshDataButton
  },
  props: {
    context: { type: Object, default: () => { return {} } },
    loadingData: { type: Boolean, default: false },
    filtersAreActive: { type: Boolean, default: false },
    expandTableFilters: { type: Boolean, default: false },
    expandSettings: { type: Boolean, default: false }
  }
}
</script>

<template>
  <div>
    <div class="pl-3">
      {{ $t('general.filters.activeFilters') }}
    </div>
    <v-chip
      v-for="(value, key, index) in activeFilters"
      :key="index"
      class="mr-3 mt-2"
      :input-value="true"
      color="primary--text"
      close
      @click:close="removeActiveFilter(index, key)"
    >
      <slot
        name="label"
        :item="key"
      />
    </v-chip>
  </div>
</template>

<script>
import { filterChips } from '@/mixins/filter_chips'

export default {
  name: 'FilterChips',
  mixins: [filterChips]
}
</script>

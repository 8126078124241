import { getViewContext } from '@/api/utils/route_params'

export const listViews = {
  data () {
    return {
      tableToolbarContext: {
        enableRefresh: true,
        enableFilters: true,
        enableSettings: false,
        enableTitle: false,
        title: null
      },
      tableData: {
        itemCount: 0,
        pageCount: 0,
        items: []
      },
      loadingData: false,
      activeFilters: {},
      paginationOptions: {
        sortBy: ['createdAt'],
        sortDesc: [true],
        page: 1,
        itemsPerPage: 15,
        itemsRange: [15, 30, 50]
      },
      expandTableFilters: false,
      expandSettings: false,
      viewContext: getViewContext()
    }
  },
  computed: {
    filtersAreActive () {
      return Object.keys(this.activeFilters).length > 0
    },
    tableWrapStyle () {
      return this.$vuetify.breakpoint.smAndUp
        ? 'white-space: nowrap'
        : ''
    }
  },
  watch: {
    paginationOptions: {
      handler () {
        this.getTableData()
      },
      deep: true
    },
    activeFilters: {
      handler () {
        this.paginationOptions.page === 1
          ? this.getTableData()
          : this.paginationOptions = {
            ...this.paginationOptions,
            page: 1
          }
      },
      deep: true
    }
  }
}

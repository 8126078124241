<template>
  <v-btn
    outlined
    rounded
    color="primary"
    :loading="loadingData"
    :disabled="loadingData"
    @click.native="$emit('refreshData')"
  >
    <v-icon left>
      {{ $vuetify.icons.values.refresh }}
    </v-icon>
    {{ $t('general.refresh') }}
  </v-btn>
</template>

<script>
export default {
  name: 'RefreshDataButton',
  props: {
    loadingData: { type: Boolean, default: false }
  }
}
</script>

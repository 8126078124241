<template>
  <v-btn
    :icon="$vuetify.breakpoint.smAndDown"
    :color="iconColor"
    text
    rounded
    :input-value="expandTableFilters"
    @click.stop="$emit('toggle-table-filters')"
  >
    <v-icon
      :left="$vuetify.breakpoint.mdAndUp"
      :color="iconColor"
    >
      {{ $vuetify.icons.values.filter }}
    </v-icon>
    <span v-show="$vuetify.breakpoint.mdAndUp">{{ $t('general.filters.title') }}</span>
  </v-btn>
</template>

<script>
export default {
  name: 'OpenFiltersButton',
  props: {
    expandTableFilters: { type: Boolean, default: false }
  },
  computed: {
    iconColor () {
      return this.expandTableFilters ? 'blue' : 'grey darken-2'
    }
  }
}
</script>

<template>
  <v-btn
    rounded
    :icon="$vuetify.breakpoint.smAndDown"
    text
    color="grey darken-2"
    class="mr-3"
    :input-value="expandSettings"
    @click.stop="$emit('toggle-settings')"
  >
    <v-icon :left="$vuetify.breakpoint.mdAndUp">
      {{ $vuetify.icons.values.settings }}
    </v-icon>
    <span v-show="$vuetify.breakpoint.mdAndUp">{{ $t('general.settings') }}</span>
  </v-btn>
</template>

<script>
export default {
  name: 'OpenSettingsButton',
  props: {
    expandSettings: { type: Boolean, default: false }
  }
}
</script>

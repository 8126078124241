<template>
  <v-navigation-drawer
    v-model="sideSheetState"
    fixed
    floating
    temporary
    hide-overlay
    right
    :width="sideSheetWidth"
  >
    <global-events
      @keyup.enter="$bus.emit('trigger:update-active-filters')"
      @keyup.esc="sideSheetState = false"
    />
    <template v-slot:prepend>
      <v-toolbar flat>
        <v-toolbar-title>
          <v-btn
            icon
            @click="sideSheetState = false"
          >
            <v-icon>
              {{ $vuetify.icons.values.close }}
            </v-icon>
          </v-btn>
          <slot name="title">
            {{ $t('filters.generic') }}
          </slot>
        </v-toolbar-title>
        <v-fade-transition>
          <v-divider
            v-show="filtersAreActive"
            inset
            vertical
            class="ml-5 mr-3"
          />
        </v-fade-transition>
        <reset-filters-button :filters-are-active="filtersAreActive" />
      </v-toolbar>
    </template>
    <v-card flat>
      <v-card-text>
        <slot name="filters" />
      </v-card-text>
    </v-card>
    <template v-slot:append>
      <filters-bottom-toolbar
        :loading-data="loadingData"
        @close-filters="sideSheetState = false"
      />
    </template>
  </v-navigation-drawer>
</template>

<script>
import ResetFiltersButton from '@/components/buttons/filters/ResetFilters'
import FiltersBottomToolbar from '@/components/toolbars/FiltersBottomToolbar'
import GlobalEvents from 'vue-global-events'

export default {
  name: 'FiltersSideSheet',
  components: {
    ResetFiltersButton,
    FiltersBottomToolbar,
    GlobalEvents
  },
  props: {
    expandTableFilters: { type: Boolean, default: false },
    loadingData: { type: Boolean, default: false },
    filtersAreActive: { type: Boolean, default: false }
  },
  computed: {
    sideSheetState: {
      get () {
        return this.expandTableFilters
      },
      set (value) {
        this.$emit('update:expand-table-filters', value)
      }
    },
    sideSheetWidth () {
      return this.$vuetify.breakpoint.mdAndUp
        ? '50%'
        : '100%'
    }
  }
}
</script>
